import { useTranslation } from 'i18n/TranslationContext'
import React from 'react'
import { Button } from '@chakra-ui/react'
import { useFormContext } from 'react-hook-form'

export const ContinueSubmitButton = () => {
  const { t } = useTranslation()
  const { formState: { isSubmitting }, trigger } = useFormContext()
  return (
    <Button
      fontWeight="500"
      colorScheme="zettaBlue"
      type="submit"
      isLoading={isSubmitting}
      onClick={() => {
        trigger()
      }}
    >
      {t('Continue')}
    </Button>
  )
}
