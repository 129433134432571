import { useTranslation } from 'i18n/TranslationContext'
import { usePageTitle } from '../../usePageTitle'
import { CourseIntroBlock } from './CourseIntroBlock'
import { CourseContentBlock } from './CourseContentBlock'
import { CourseDetails } from './CourseDetails'
import { useCoursePageContext } from './CoursePageContext'
import { HeaderBlock } from './HeaderBlock'
import { AboutBlock } from './AboutBlock'
import { QuoteBlock } from './QuoteBlock'
import { MeetYourTeacherBlock } from './MeetYourTeacherBlock'
import { CourseAuthorsBlock } from './CourseDetails'

const NewCourseBlocks = () => {
  const {
    data: {
      content:{
        landingBlocks
      },
      waitlist: hasWaitlist
    }
  } = useCoursePageContext().courseContentData

  return landingBlocks.order.public.map((blockId) => {
    switch (landingBlocks[blockId].type) {
    case 'header':
      return (
        <HeaderBlock
          key={blockId}
          specification={landingBlocks[blockId]}
        />
      )
    case 'about':
      return (
        <AboutBlock
          key={blockId}
          specification={landingBlocks[blockId]}
        />
      )
    case 'episodes':
      return !hasWaitlist ? (
        <CourseContentBlock
          key={blockId}
          specification={landingBlocks[blockId]}
        />
      ) : null
    case 'meet_your_teachers':
      return !hasWaitlist ? (
        <CourseAuthorsBlock
          key={blockId}
          taughtBy={landingBlocks[blockId].authors}
        />
      ) : null
    case 'meet_your_teacher':
      return !hasWaitlist ? (
        <MeetYourTeacherBlock
          key={blockId}
          specification={landingBlocks[blockId]}
        />
      ) : null
    case 'quote':
      return !hasWaitlist ? (
        <QuoteBlock
          key={blockId}
          specification={landingBlocks[blockId]}
        />
      ) : null
    default:
      return null
    }
  })
}

const CourseBlocks = () => {
  const {
    data: {
      content:{
        landingBlocks
      }
    }
  } = useCoursePageContext().courseContentData


  if (!landingBlocks) {
    return (
      <>
        <CourseIntroBlock />
        <CourseContentBlock />
        <CourseDetails />
      </>
    )
  }

  return (
    <>
      <NewCourseBlocks />
    </>
  )
}

export const PageContent = () => {
  const { t } = useTranslation()
  const { data: { name } } = useCoursePageContext().courseContentData
  usePageTitle(`${name} - ${t('Course')}`)

  return (
    <main role="main">
      <CourseBlocks />
    </main>
  )
}
