import { useTranslation } from 'i18n/TranslationContext'
import React, { useEffect } from 'react'
import { Box, Text, Select, FormControl, useToast } from '@chakra-ui/react'
import { useListUserGroupsQuery } from 'features/userGroupsApi'
import { useFormContext } from 'react-hook-form'
import { FormLabel } from '@chakra-ui/react'
import { AppLoadingState } from 'AppLoadingState'
import FormErrorDisplay from 'components/forms/FormErrorDisplay'

const FormContent = () => {
  const { t } = useTranslation()
  const { data: userGroups, isLoading } = useListUserGroupsQuery()
  const { formState: { errors }, register } = useFormContext()
  const toast = useToast()

  const errorCount = errors ? Object.keys(errors).length : 0

  useEffect(() => {
    if (errorCount > 0) {
      toast({
        title: t('You have {{errorCount}} fields left to review. Please complete each section before continuing.', { errorCount }),
        status: 'error',
        position: 'top',
        containerStyle: {
          maxWidth: 'unset',
          width: '100%',
          margin: '0'
        }
      })
    }
  }, [errorCount, toast, t])

  if (isLoading) {
    return <AppLoadingState />
  }

  return (
    <Box width="full">
      <Text mb={3} fontSize="20px" fontFamily="Poppins" textAlign="center">{t('At Making Space, our goal is to create a more equitable and accessible future of work for Disabled, low income and traditionally underrepresented talent.')}</Text>
      <Text mb={12} fontSize="20px" fontFamily="Poppins" textAlign="center">{t('To help us create a more inclusive platform for our talent, please consider providing the information below.')}</Text>
      {userGroups.map((group) => (
        <FormControl
          key={group.name}
          mb={5}
          mt={10}
          isRequired
          isInvalid={!!errors[`ug-${group.name}`]}
        >
          <FormLabel
            htmlFor={`ug-${group.name}`}
            fontSize="24px"
            fontFamily="Poppins"
            fontWeight="600"
            mb={3}
          >
            {`${group.label}:`}
          </FormLabel>
          <Select
            id={`ug-${group.name}`}
            name={`ug-${group.name}`}
            size="lg"
            defaultValue=""
            {...register(`ug-${group.name}`, {
              required: t('This field is required. Please choose an option.')
            })}
          >
            <option value="" disabled>{t('Select an option')}</option>
            {group.user_group_items.map((item) => (
              <option key={item.id} value={item.id}>{item.label}</option>
            ))}
          </Select>
          <FormErrorDisplay name={`ug-${group.name}`} errorVal={errors} />
        </FormControl>
      ))}
    </Box>
  )
}

export default FormContent
