import React from 'react'
import { Button, Link as ChakraLink } from '@chakra-ui/react'
import { useTranslation } from 'i18n/TranslationContext'
import { useTrackEventMutation } from 'features/analyticsApi'

const DownloadResumeButton = ({ id, resumeFilename, resumeDownloadUrl, talentProfileId, ...props }) => {
  const { t } = useTranslation()
  const [trackEvent] = useTrackEventMutation()

  return (
    <Button
      className="list-resume-download-button"
      as={ChakraLink}
      download={resumeFilename}
      href={resumeDownloadUrl}
      fontSize="16px"
      fontWeight="500"
      padding="0 12px"
      mt="5px"
      colorScheme="zettaWhiteOutline"
      id={id}
      {...props}
      onClick={() => trackEvent({
        event: 'Resume Downloaded',
        sourceObject: 'TalentProfile',
        sourceObjectID: talentProfileId
      })}
    >
      {t('Download Resume')}
    </Button>
  )
}

export default DownloadResumeButton
