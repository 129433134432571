import { Box, Button, Center, Flex, Text, Tooltip, VStack } from '@chakra-ui/react'
import { useTranslation } from 'i18n/TranslationContext'
import { PortfolioLinks, Subtitle, TalentPhoto } from 'components/partner/talent_profile_view'
import DownloadResumeButton from 'components/buttons/DownloadResumeButton'

export const ProfileCard = ({
  data: {
    contact_requests: [contactRequest],
    name,
    location,
    pronouns,
    profession,
    bio,
    photo_url: photoUrl,
    photo_alt_text: photoAltText,
    resume_download_url: resumeDownloadUrl,
    resume_filename: resumeFilename,
    portfolio_url: portfolioUrl,
    other_url: otherUrl,
    id: talentProfileId,
  },
  onClose
}) => {
  const { t } = useTranslation()

  // DISABLED: See backlog for spike
  // const { isOpen: isContactRequestOpen, onOpen:onContactRequestOpen, onClose: onContactRequestClose } = useDisclosure() // for search drawer

  return (
    <>
      {/* DISABLED: See backlog for spike */}
      {/* <RequestContactDrawer isOpen={isContactRequestOpen} onClose={onContactRequestClose} /> */}

      <Flex
        flexDirection={['column-reverse', null, 'row']}
        justifyContent="space-between"
        className="talent-profile-card"
      >
        <Flex
          flexDirection={['column-reverse', null, 'column']}
          flexBasis="50%"
          flexGrow="1"

        >
          {/* Left Column */}
          <Box
            marginBottom="54px"
            width={['100%', null, 'unset']}
          >
            <Button
              onClick={onClose}
              colorScheme="zettaPurple"
              width={['100%', null, 'unset']}
              fontWeight="500"
            >
              {t('Get back to list')}
            </Button>
          </Box>
          <Box marginBottom="24px">
            <Subtitle
              pronouns={pronouns}
              location={location}
            />
          </Box>
          <Box marginBottom="8px">
            <Box as="h1"
              color="var(--navy-blue, #0F204A)"
              fontFamily="Spline Sans Mono"
              fontSize="44px"
              fontWeight="bold"
              textTransform="uppercase"
            >
              {name}
            </Box>
          </Box>
          <Flex>
            <Box
              marginLeft="-20px"
              minWidth="172px"
              minHeight="64px"
              flexGrow="0"
              bgImage={`${process.env.PUBLIC_URL}/profession-tape.svg`}
              bgRepeat="no-repeat"
              bgPosition="center"
              bgSize="100% 100%"
              padding="12px 16px 0px 20px"
              color="var(--Navy-Blue, #0F204A)"
              fontFamily="Spline Sans Mono"
              fontSize="28px"
              fontWeight="700"
              textTransform="uppercase"
            >
              <Tooltip label={profession}>
                <Text noOfLines={1} >
                  {profession}
                </Text>
              </Tooltip>
            </Box>
          </Flex>
          <Box
            marginTop="16px"
            color="var(--navy-blue, #0F204A)"
            fontFamily="Poppins"
          >
            <Text whiteSpace="pre-wrap">{bio}</Text>
          </Box>
        </Flex>

        {/* Right Column */}
        <Box
          flexBasis="40%"
          flexShrink="0"
        >
          <VStack>
            {/* profile pic tape */}
            <Center
              marginLeft={['-32px', null, '0']}
              marginRight={['-22px', null, '0']}
            >
              <Box as="img"
                width="100%"
                maxWidth="280px"
                opacity="0.85"
                src={`${process.env.PUBLIC_URL}/profile-tape.svg`}
                alt=""
              />
            </Center>

            {/* talent photo */}
            <TalentPhoto
              photoUrl={photoUrl}
              photoAltText={photoAltText}
            />

            {/* Download Resume and Portfolio Links */}
            <Flex
              flexDirection="column"
              alignItems="stretch"
              marginTop="20px"
              width="min-content"
              minWidth="230px"
              className="resume-portfolio-links"
            >
              {/* DISABLED: See backlog for spike */}
              {/* <RequestContacts
              id={talentProfileId}
              contactRequest={contactRequest}
              onContactRequestOpen={onContactRequestOpen}
            /> */}
              {resumeFilename &&
                <DownloadResumeButton
                  resumeFilename={resumeFilename}
                  resumeDownloadUrl={resumeDownloadUrl}
                  talentProfileId={talentProfileId}
                  id="modal-resume-download-button"
                />
              }

              { (portfolioUrl || otherUrl) &&
              <PortfolioLinks portfolioUrl={portfolioUrl} otherUrl={otherUrl} />
              }
            </Flex>
          </VStack>

        </Box>
      </Flex>
    </>

  )
}
