import { useTranslation } from 'i18n/TranslationContext'
import { useLocation, useNavigate } from 'react-router-dom'
import { Center } from '@chakra-ui/react'
import { ResultsCounts } from './ResultsCounts'
import { PaginationButtons } from 'components/buttons/PaginationButtons'

export const Pagination = ({ listQuery }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const {
    data,
    isSuccess
  } = listQuery

  const handlePageChange = ({ page, perPage }) => {
    const searchParams = new URLSearchParams(location.search)
    if (page) searchParams.set('page', page.toString())
    if (perPage) searchParams.set('per_page', perPage.toString())
    navigate({
      pathname: location.pathname,
      search: searchParams.toString()
    }, { replace: true })
  }

  if (!isSuccess) return null
  if (data.pagination.count === 0) return null

  return (
    <>
      <ResultsCounts pagination={data.pagination} />
      <nav role="navigation" aria-label={t('List pages')}>
        <Center marginTop="20px">
          <PaginationButtons
            pagination={data.pagination}
            onPageChange={handlePageChange}
          />
        </Center>
      </nav>
    </>
  )
}
