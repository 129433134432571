import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const convertFormParamsToData = (props) => {
  const formData = new FormData()

  const regularProps =[
    'name', 'first_name', 'last_name', 'pronouns', 'profession', 'bio'
  ]

  regularProps.forEach((prop) => {
    if (props[prop] === null || typeof props[prop] === 'undefined') { return }

    formData.append(prop, props[prop])
  })

  if (typeof props.altText === 'string') {
    formData.append('photo_alt_text', props.altText)
  }

  if (props.portfolioUrl) {
    formData.append('portfolio_url', props.portfolioUrl)
  }

  if ( props.otherUrl) {
    formData.append('other_url', props.otherUrl)
  }

  if (props.deletePhoto) {
    formData.append('delete_photo', true)
  } else if (props.photo) {
    formData.append('photo', props.photo[0])
  }

  // TODO: This param doesn't seem to be permitted by the API:
  // Unpermitted parameters :location.place_id, :id. Context: ...
  // Location can still be updated. Is this param needed?
  if (props.location) {
    formData.append('location.place_id', props.location.place_id)
  } else {
    formData.append('location.place_id', '')
  }

  if (props.deleteResume) {
    formData.append('delete_resume', true)
  } else if (props.resume) {
    formData.append('resume', props.resume[0])
  }

  return formData
}

export const talentProfilesApi = createApi({
  reducerPath: 'talentProfilesApi',
  baseQuery: fetchBaseQuery(
    {
      baseUrl: '/api',
      prepareHeaders: (headers) => {
        headers.set('Accept', 'application/json')
        return headers
      }
    }
  ),
  endpoints: (builder) => ({
    listTalentProfiles: builder.query({
      query: ({
        pageNumber,
        perPage,
        partnerProfileId,
        courseId,
        opportunityId,
        withAssignments,
        searchDrawerParams = {},
      }) => {
        const params = new URLSearchParams()

        if (withAssignments) {
          params.set('with_assignments', 1)
        }

        if (opportunityId) {
          params.set('opportunity_id', opportunityId)
        }

        if (courseId) {
          params.set('course_id', courseId)
        }

        if (partnerProfileId) {
          params.set('course_owner_id', partnerProfileId)
        }

        if (pageNumber) {
          params.set('page', pageNumber)
        }

        if (perPage) {
          params.set('per_page', perPage)
        }

        // Add search parameters
        Object.entries(searchDrawerParams).forEach(([key, value]) => {
          if (Array.isArray(value)) {
            // For arrays, join the values into a comma-separated string
            params.set(key, value.join(','))
          } else {
            // For other types, set the value directly
            params.set(key, value)
          }
        })

        return `/talent_profiles?${params.toString()}`
      },
      providesTags: ['TalentList']
    }),

    sendContactRequest: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/talent_profiles/${id}/contact_requests`,
        method: 'POST',
        body
      }),
      invalidatesTags: (result, error) => error ? [] : ['CurrentTalentProfile']
    }),

    getTalentProfile: builder.query({
      query: ({ id, mode = '', forPartner }) => {
        const params = new URLSearchParams()

        if (mode) { params.set('mode', mode) }
        if (forPartner) { params.set('for_partner', forPartner) }

        return `/talent_profiles/${id}?${params.toString()}`
      },
      providesTags: ['CurrentTalentProfile']
    }),

    updateTalentProfile: builder.mutation({
      query: ({ id, ...restProps }) => ({
        url: `/talent_profiles/${id}`,
        method: 'PATCH',
        formData: true,
        body: convertFormParamsToData(restProps)
      }),
      invalidatesTags: (result, error) => error ? [] : ['CurrentTalentProfile']
    })
  })
})

export const {
  useGetTalentProfileQuery,
  useUpdateTalentProfileMutation,
  useListTalentProfilesQuery,
  useSendContactRequestMutation,
  useLazyListTalentProfilesQuery,
} = talentProfilesApi
