import { useTranslation } from 'i18n/TranslationContext'
import { useCurrentCourseQuery } from './useCurrentCourseQuery'
import { useCoursePageContext } from './CoursePageContext'
import { Button, Tooltip } from '@chakra-ui/react'
import { HiPlay } from 'react-icons/hi'
import { WatchingButton } from './WatchingButton'
import { WaitlistButton } from 'components/buttons'

const PrivateEnrollButton = () => {
  const {
    data: {
      content: {
        enrollForm
      }
    }
  } = useCurrentCourseQuery()
  const { t } = useTranslation()
  return (
    <Button
      as="a"
      href={enrollForm}
      size="lg"
      fontSize="20px"
      fontWeight="normal"
      colorScheme="zettaWhite"
      variant="solid"
      height="60px"
      width={['100%', '100%', 'unset']}
      rightIcon={<HiPlay />}
      iconSpacing="24px"
    >
      {t('Apply Now')}
    </Button>
  )
}

const AppliedStatusButton = () => {
  const { t } = useTranslation()
  return (
    <Button
      as="div"
      size="lg"
      colorScheme="zettaPurple"
      fontWeight="500"
      opacity="0.6"
    >
      {t('Your application in review')}
    </Button>
  )
}

export const MainButton = () => {
  const { t } = useTranslation()
  const {
    data: {
      'access?': hasAccess,
      'applied?': hasApplied,
      'waitlist': hasWaitlist,
      user_course_enrolment
    }
  } = useCurrentCourseQuery()
  const { isPartnerExperience } = useCoursePageContext()

  if (hasWaitlist) {
    const isWaitlisted = user_course_enrolment?.status === 'waitlisted'

    if (isWaitlisted) {
      return (
        <Tooltip label={t('You\'re on the list. Stay tuned for launch.')} >
          <span>
            <WaitlistButton
              isDisabled={true}
            />
          </span>
        </Tooltip>
      )
    }

    return <WaitlistButton />
  }

  if (hasAccess || isPartnerExperience) {
    return <WatchingButton />
  }

  if (hasApplied) {
    return <AppliedStatusButton />
  }

  return <PrivateEnrollButton />
}
