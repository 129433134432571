import { useTranslation } from 'i18n/TranslationContext'
import React from 'react'
import { Box, Center, Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react'
import { FaUserCircle, FaLightbulb } from 'react-icons/fa'
import { HiPencilSquare } from 'react-icons/hi2'
import HeaderAvatar from './HeaderAvatar'
import { ShareFeedbackMenuItem } from '../ShareFeedbackButton'
import { LogOutDropdownItem } from '../../features/sessions'
import { useOutsideClick } from '@chakra-ui/react'

const HeaderDropdown = ({ name, photoUrl, headerType, experienceType }) => {
  const { t } = useTranslation()
  const ref = React.useRef()
  const addDropdownHighlight = (e, headerType) => {
    let colorChange = headerType === 'partner' ? '#fff' : '#0F204B'
    ref.current.querySelector('.dropdown-display-name-wrapper').style.fontWeight = 'bold'
    ref.current.querySelector('.dropdown-avatar-highlight-background').style.background = colorChange
  }

  const removeDropdownHighlight = (e, headerType) => {
    if(window.getComputedStyle(ref.current.parentElement.querySelector('.chakra-menu__menu-list')).visibility === 'hidden' || e.outsideClick) {
      ref.current.querySelector('.dropdown-avatar-highlight-background').style.background = 'none'
      ref.current.querySelector('.dropdown-display-name-wrapper').style.fontWeight = 'normal'
    }
  }

  useOutsideClick({
    ref: ref,
    handler: () => removeDropdownHighlight({ outsideClick: true }),
  })

  const isPartnerUX = headerType === 'partner' || experienceType === 'partner_experience'

  return (
    <Menu >
      <MenuButton
        marginLeft="auto"
        marginRight="0"
        ref={ref}
        aria-label={t('User Menu')}
        className="dropdown-display-button"
        aria-controls="menu-list"
        onMouseLeave={(e) => removeDropdownHighlight(e, headerType)}
        onMouseEnter={(e) => addDropdownHighlight(e, headerType)}
        onClick={(e) => addDropdownHighlight(e, headerType)}
        onFocus={(e) => addDropdownHighlight(e, headerType)}
        onBlur={(e) => removeDropdownHighlight(e, headerType)}
      >
        <Center>
          <Box className="dropdown-display-name-wrapper" color={headerType === 'partner' ? '#fff' : '#0F204B'} fontFamily="Spline Sans Mono" mr={2} display="inline-block">
            {name}
          </Box>
          <Box className="dropdown-avatar-highlight-background"
            borderRadius="50%"
            width="52px"
            height="52px"
            padding={photoUrl ? '0px' : '2px'}
          >
            <HeaderAvatar className="dropdown-display-avatar-wrapper" photoUrl={photoUrl} headerType={headerType} />
          </Box>
        </Center>
      </MenuButton>
      <MenuList zIndex={7000} >
        <MenuItem id="menu-list" as="a" href={isPartnerUX ? '/partner/edit' : '/talent/edit' } icon={<FaUserCircle />}>
          {isPartnerUX
            ? t('Partner Profile')
            : t('My Profile')}
        </MenuItem>
        {!isPartnerUX && (
          <MenuItem id="self-id-menu-item" as="a" href="/talent/onboard/self-id" icon={<HiPencilSquare />}>
            {t('Self-ID Form')}
          </MenuItem>
        )}
        <ShareFeedbackMenuItem />
        <MenuItem as="a" href="/support" icon={<FaLightbulb />}>
          {t('Support')}
        </MenuItem>
        <LogOutDropdownItem />
      </MenuList>
    </Menu>
  )
}

export default HeaderDropdown
