export const generatePageSeries = (current, total) => {
  const pages = []
  const maxVisiblePages = 5

  if (total <= maxVisiblePages) {
    return Array.from({ length: total }, (_, i) => i + 1)
  }

  let start = Math.max(1, current - Math.floor(maxVisiblePages / 2))
  let end = start + maxVisiblePages - 1

  if (end > total) {
    end = total
    start = Math.max(1, end - maxVisiblePages + 1)
  }

  for (let i = start; i <= end; i++) {
    pages.push(i)
  }

  return pages
}
