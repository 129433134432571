import { Box, Center, Flex, Hide, Show, Text } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { OpportunityPoster } from 'components/opportunities'

const Column1Desktop = ({ id, name, poster, partnerView }) => (
  <Flex>
    <OpportunityPoster
      id={id}
      name={name}
      poster={poster}
    />
    <Center whiteSpace="normal">
      <Box>
        <Text
          fontSize="24px"
          lineHeight="1.5"
          fontFamily="Poppins"
          fontWeight="600"
          color="#0F204A"
        >
          <Link to={partnerView ? `/opportunities/${id}/applications` : `/opportunities/${id}`}>
            {name}
          </Link>
        </Text>
      </Box>
    </Center>
  </Flex>
)

const Column1Mobile = ({ id, name, poster, companyName, partnerView }) => (
  <Link to={partnerView ? `/opportunities/${id}/applications` : `/opportunities/${id}`}>
    <Flex paddingBottom="12px">
      <OpportunityPoster
        id={id}
        name={name}
        poster={poster}
      />
      <Flex
        whiteSpace="normal"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Text
          fontSize="20px"
          fontFamily="Spline Sans Mono"
          fontWeight="700"
        >
          {name}
        </Text>
        {companyName && (
          <Text
            fontSize="16px"
            fontFamily="Poppins"
            fontWeight="500"
          >
            {companyName}
          </Text>
        )}
      </Flex>
    </Flex>
  </Link>
)

const Column1 = ({ id, name, companyName, poster, partnerView }) => (
  <>
    <Hide above="768px">
      <Column1Mobile
        id={id}
        name={name}
        poster={poster}
        companyName={companyName}
        partnerView={partnerView}
      />
    </Hide>
    <Show above="md">
      <Column1Desktop
        id={id}
        name={name}
        poster={poster}
        partnerView={partnerView}
      />
    </Show>
  </>
)

export default Column1
