import { Button } from '@chakra-ui/react'
import { useTranslation } from 'i18n/TranslationContext'
import { useParams } from 'react-router-dom'
import { useEnrollToTheCourseMutation } from 'features/coursesApi'
import { useGetCurrentUserQuery } from 'features/sessionsApi'

const WaitlistButton = ({ ...props }) => {
  const [enrollToCourse, { isLoading }] = useEnrollToTheCourseMutation()
  const { data: { id: userId } } = useGetCurrentUserQuery()
  const { courseId } = useParams()
  const { t } = useTranslation()

  return (
    <Button
      onClick={() => {
        enrollToCourse({
          userId,
          courseId
        })
      }}
      size="lg"
      fontSize="20px"
      fontWeight="normal"
      colorScheme="zettaWhite"
      variant="solid"
      height="60px"
      width={['100%', '100%', 'unset']}
      isLoading={isLoading}
      {...props}
    >
      {t('Join the Waiting List')}
    </Button>
  )
}

export default WaitlistButton
