import { Button } from '@chakra-ui/react'
import { useTranslation } from 'i18n/TranslationContext'
import { useTrackEventMutation } from 'features/analyticsApi'
import { useLocation } from 'react-router-dom'

export const ExportCsvButton = ({ id, useExportOptions, ...props }) => {
  const { t } = useTranslation()
  const { handleExportCsv, isLoading } = useExportOptions()
  const [trackEvent] = useTrackEventMutation()
  const url = useLocation()
  const currentParams = new URLSearchParams(url.search)
  const courseId = currentParams.get('course_id')

  const handleClick = () => {
    handleExportCsv()
    courseId && trackEvent({
      event: 'Talent List CSV Exported',
      sourceObject: 'Course',
      sourceObjectID: courseId
    })
  }

  return (
    <Button
      size="lg"
      colorScheme="zettaBlue"
      fontSize="16px"
      fontWeight="500"
      onClick={handleClick}
      isLoading={isLoading}
      {...props}
    >
      {t('Export to CSV')}
    </Button>
  )
}

