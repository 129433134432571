import { useDispatch } from 'react-redux'
import { useTranslation } from 'i18n/TranslationContext'
import { Box, Button, Flex, Progress, Show, Tr, Text, useDisclosure } from '@chakra-ui/react'
import { useTalentProfileListData } from './useTalentProfileListData'
import { TableWrapper } from '../../shared/TableWrapper'
import { ProfileColumn } from './TalentItems/ProfileColumn'
import { TableItem } from '../../shared/TableItem'
import { TableItemMap } from '../../shared/TableItemMap'
import ProfileDrawer from 'components/partner/talent/list_page/profile_drawer/ProfileDrawer'
import { setCurrentTalentProfileId } from 'features/partner_ux/partnerUxSlice'
import DownloadResumeButton from 'components/buttons/DownloadResumeButton'

const ProgressLabel = ({ percentage }) => {
  const { t } = useTranslation()
  return t('coursesProgress', { percentage })
}

const ViewProfileButton = ({ id, onClick, ...props }) => {
  const { t } = useTranslation()
  return (
    <Button
      colorScheme="zettaBlue"
      fontSize="16px"
      fontWeight="500"
      padding="0 12px"
      onClick={onClick}
      {...props}
    >
      {t('View profile')}
    </Button>
  )
}

const Column1 = ({
  id,
  name,
  photo_url: photoUrl,
  photo_alt_text: photoAltText,
  profession,
  location,
  pronouns,
  enrolled_count: enrolledCount,
  course_progress: courseProgress,
  onProfileClick
}) => (
  <ProfileColumn
    name={name}
    photoAltText={photoAltText}
    photoUrl={photoUrl}
    profession={profession}
    location={location}
    pronouns={pronouns}
    onProfileClick={() => onProfileClick(id)}
  >
    <Show below="767px">
      <Box
        fontFamily="Poppins"
        fontSize="14px"
        fontWeight="500"
        color="#070F22"
        opacity="0.6200000047683716"
      >
        <ProgressLabel percentage={Math.floor(courseProgress)} />
      </Box>
    </Show>
  </ProfileColumn>
)

const Column2 = ({ course_progress: courseProgress }) => {
  const { t } = useTranslation()
  return (
    <Show above="md">
      <Text fontSize="16px" textAlign="left">{Math.floor(courseProgress)}%</Text>
      <Progress
        colorScheme="green"
        height="14px"
        value={courseProgress}
        aria-label={t('Course completion progress')}
        title={t('Course completion progress')}
      />
    </Show>
  )
}

const Column3 = ({
  id,
  resume_filename: resumeFilename,
  resume_download_url: resumeDownloadUrl,
  onProfileClick
}) => {
  return (
    <Flex
      justifyContent="center"
      flexDirection="column"
      alignItems={['center', 'center', 'flex-end']}
      marginTop={['16px', '16px', 'unset']}
    >
      <ViewProfileButton id={id} onClick={() => onProfileClick(id)}
        width={['100%', '100%', 'unset']}
        minWidth={[null, null, '178px']}
      />

      {resumeFilename &&
        <DownloadResumeButton id={id}
          resumeFilename={resumeFilename}
          resumeDownloadUrl={resumeDownloadUrl}
          talentProfileId={id}
          width={['100%', '100%', 'unset']}
          minWidth={[null, null, '178px']}
        />
      }
    </Flex>
  )
}

export const TalentTable = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { isOpen, onOpen, onClose } = useDisclosure() // for search drawer

  // on click of view profile, set the talent profile id to the store,
  // and open the profile drawer
  const handleViewProfileClick = (id) => {
    dispatch(setCurrentTalentProfileId(id))
    onOpen()
  }

  return (
    <>
      <ProfileDrawer isOpen={isOpen} onClose={onClose} />
      <TableWrapper
        headings={[
          t('talent'),
          t('progress'),
          t('actions')
        ]}
      >
        <TableItemMap listQuery={useTalentProfileListData()}
          emptyStateCopy={t('No talent found')}
        >
          {
            (props) => (
              <Tr key={props.id}>
                <TableItem
                  columns={[
                    <Column1 key={1} {...props} onProfileClick={handleViewProfileClick} />,
                    <Column2 key={2} {...props} />,
                    <Column3 key={3} {...props} onProfileClick={handleViewProfileClick} />
                  ]}
                />
              </Tr>
            )
          }
        </TableItemMap>
      </TableWrapper>
    </>

  )
}
