import React from 'react'
import { useForm } from 'react-hook-form'
import { useGetCurrentUserQuery } from 'features/sessionsApi'
import { useGetUserUserGroupItemsQuery } from 'features/userGroupsApi'

export const useUserGroupForm = () => {
  const { data: { id } } = useGetCurrentUserQuery()
  const { data: userGroupItems } = useGetUserUserGroupItemsQuery(id)

  const form = useForm({
    defaultValues: {
      userId: id
    }
  })

  React.useEffect(() => {
    if (userGroupItems) {
      const userGroupItemIds = []
      userGroupItems.forEach((item) => {
        userGroupItemIds.push(item.id)
        form.setValue(`ug-${item.user_group.name}`, item.id)
      })
    }
  }, [userGroupItems, form])

  return form
}
