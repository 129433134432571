import { Box, Center, Tooltip, Link as ChakraLink, Button, HStack, VStack } from '@chakra-ui/react'
import { useTranslation } from 'i18n/TranslationContext'
import { Link } from 'react-router-dom'
import { H2 } from 'shared/typography'
import { EpisodePreview } from '../CoursePage/CourseContentBlock/EpisodePreview'
import { useCoursePageContext } from '../CoursePage/CoursePageContext'
import { useFindEpisodeNumber, useGetStepInfo, useShowTakeQuizButton, useStepMap, useVideoCompleted } from '../hooks'
import { useDispatch } from 'react-redux'
import { openQuizForStepId, setQuizMode } from 'features/quiz/talentQuizSlice'
import { useHasCompletedQuiz } from 'utils/quizzes/talentQuiz'
import { TALENT_QUIZ_MODE } from 'constants'
import { useVideoPlayer } from 'CourseFeature/EpisodePage/useVideoPlayer'
import { useLazyFetchQuizByStepIdQuery } from 'features/quiz/quizzesApi'
import { DIRECTION } from 'constants'
import { useEffect, useRef } from 'react'
import { useTrackEventMutation } from 'features/analyticsApi'

const EpisodeNavTreatment = ({ text, className, isDisabled }) => {
  return (
    <Box
      color={ isDisabled ?
        'var(--design-subtle-2, #6c757d)':
        'var(--design-primary, #5000E8)' }
      fontWeight="500"
      fontSize="18px"
      lineHeight="normal"
      cursor={ isDisabled ? 'not-allowed' : 'pointer' }
      textDecoration={ isDisabled ? 'none' : 'underline'}
      className={ className }
      disabled={ isDisabled }
    >
      {text}
    </Box>
  )
}

export const EpisodeTitle = ({ step: { id }, isAssignment, isDisabled }) => {
  const { t } = useTranslation()
  const titleText = useEpisodeTitleText(id, isAssignment, t)

  const linkClassName = isAssignment ? 'assignment-step-label' : 'episode-step-label'
  return (
    <EpisodeNavTreatment
      text={titleText} className={linkClassName}
      isDisabled={isDisabled} isAssignment={isAssignment}
    />
  )
}

const useEpisodeTitleText = (stepId, isAssignment, t, navPrefix=false) => {
  // if has intro episode:
  // -- and episodeNumber is 1, return null
  // -- otherwise, return episodeNumber - 1
  // if doesn't have intro episode:
  // -- return episodeNumber

  const { data: { has_intro_episode: hasIntroEpisode } } = useCoursePageContext().courseContentData
  const episodeNumber = useFindEpisodeNumber(stepId)

  let titleText

  if (isAssignment) {
    titleText = (
      navPrefix ?
        t('Go to Assignment') :
        t('Assignment')
    )
  } else if (hasIntroEpisode) {
    if (episodeNumber === 1) return null
    titleText = (
      navPrefix ?
        t('Go to Episode {{episodeNumber}}', { episodeNumber: episodeNumber - 1 }) :
        t('Episode {{episodeNumber}}', { episodeNumber: episodeNumber - 1 })
    )
  } else {
    titleText = (
      navPrefix ?
        t('Go to Episode {{episodeNumber}}', { episodeNumber }) :
        t('Episode {{episodeNumber}}', { episodeNumber })
    )
  }

  return titleText
}

const TakeQuizLink = ({ stepId, disabled }) => {
  const { t } = useTranslation()
  const { hasCompletedQuiz } = useHasCompletedQuiz(stepId)
  const { pausePlayer } = useVideoPlayer()
  const label = hasCompletedQuiz ? t('Review Quiz') : t('Take Quiz')
  const [trackEvent] = useTrackEventMutation()
  const dispatch = useDispatch()

  const handleClick = () => {
    if (disabled) return
    if ( hasCompletedQuiz ) {
      dispatch(setQuizMode(TALENT_QUIZ_MODE.REVIEW))
      trackEvent({
        event: 'Quiz Reviewed',
        sourceObject: 'Step',
        sourceObjectID: stepId,
      })
    }
    pausePlayer()
    dispatch(openQuizForStepId(stepId))
  }

  return disabled ?
    <EpisodeNavTreatment text={label} className="take-quiz-link" isDisabled={disabled} />
    :
    (
      <Button
        color="var(--design-primary, #5000E8)"
        fontSize="18px"
        as={ChakraLink}
        fontWeight="700"
        fontFamily="Spline Sans Mono"
        textDecoration="none"
        textTransform="uppercase"
        variant="link"
        onClick={handleClick}
        colorScheme="zettaBlue"
      >{label}</Button>
    )
}

const EpisodeLink = ({ previousHasQuiz, stepId, stepURL, isAssignment, isDisabled, children }) => {
  const { t } = useTranslation()
  const episodeActionText = useEpisodeTitleText(stepId, isAssignment, t, true)
  const completeVideoLabel = previousHasQuiz ?
    t('Complete the video and quiz to move ahead in the course.')
    : t('Complete the video to move ahead in the course.')
  const disabledTooltip = isAssignment ?
    t('Assignment access must be granted by Making Space.')
    : completeVideoLabel
  const ariaLabel = isDisabled ? disabledTooltip : episodeActionText

  return (
    <Tooltip label={disabledTooltip} isDisabled={!isDisabled} placement="top" hasArrow arrowSize={15}>
      <Link key={stepId} to={stepURL} aria-label={ariaLabel}>{children}</Link>
    </Tooltip>
  )
}

const EpisodeNavBlock = ({ stepId, stepURL, step, isAssignment, isDisabled }) => {
  const { showTakeQuiz } = useShowTakeQuizButton(stepId)
  const videoCompleted = useVideoCompleted(stepId)
  const lastStep = useGetStepInfo(stepId, DIRECTION.PREVIOUS)
  const [fetchQuizByStepId, { data: quiz }] = useLazyFetchQuizByStepIdQuery()
  const initedQuizFetch = useRef(false)

  useEffect(() => {
    if (lastStep?.id && !initedQuizFetch.current) {
      initedQuizFetch.current = true
      fetchQuizByStepId(String(lastStep.id), true)
    }
  }, [lastStep, fetchQuizByStepId, initedQuizFetch])

  const EpisodeLinkWrapper = ({ children }) => (
    <EpisodeLink previousHasQuiz={!!quiz} stepId={stepId} stepURL={stepURL} isAssignment={isAssignment} isDisabled={isDisabled}>
      {children}
    </EpisodeLink>
  )

  return (
    <HStack className="episode-nav-block" justifyContent="space-between"
      alignItems="flex-start" textTransform="uppercase" fontFamily="Poppins"
      borderTop="1px solid #EFEFF0" pt="25px" pb="25px" spacing={5}
    >
      {/* Thumb */}
      <Center
        className="episode-preview-container"
        flexGrow={0}
        flexShrink={0}
      >
        <Center
          width="68px"
          height="56px"
          bgColor={isAssignment ? 'white' : 'black'}
          borderRadius="16px"
          overflow="hidden"
        >
          <EpisodeLinkWrapper>
            <EpisodePreview stepId={stepId} isIcon />
          </EpisodeLinkWrapper>
        </Center>
      </Center>

      {/* Right side */}
      <Box className="episode-nav-block-right" flexGrow={1}>
        {/* Row for episode name and take quiz link */}
        <HStack gap={5} justifyContent="space-between" alignItems="baseline" className="title-take-quiz-link" mb={1}>
          {/* Episode number */}
          <EpisodeLinkWrapper>
            <EpisodeTitle step={step} isAssignment={isAssignment} isDisabled={isDisabled} />
          </EpisodeLinkWrapper>

          {/* Take/Review quiz link */}
          {showTakeQuiz && <TakeQuizLink stepId={stepId} disabled={!videoCompleted} />}
        </HStack>
        {/* Like "Mood, Color and Tone" */}
        <Box fontWeight="700" className="step-name" fontSize="18px">
          {step.name}
        </Box>
      </Box>
    </HStack>
  )
}

const EpisodeList = () => {
  const stepMaps = Object.entries(useStepMap())

  return stepMaps.map((stepMap) => {
    const [stepId, { stepURL, step, isAssignment, isDisabled }] = stepMap

    return <EpisodeNavBlock
      key={stepId}
      stepId={stepId}
      stepURL={stepURL}
      step={step}
      isAssignment={isAssignment}
      isDisabled={isDisabled}
    />
  })
}

export const EpisodeNavigation = () => {
  const { t } = useTranslation()
  return (
    <Box as="section"
      position="relative"
      pt="32px" pl="24px" pr="32px" pb="32px"
      bgColor="white"
      width="100%"
      overflowY="scroll"
    >
      <H2
        fontSize="18px"
        color="#0F204B"
        mb="26px"
      >
        {t('Episodes')}
      </H2>
      <VStack spacing={0} alignItems="normal">
        <EpisodeList />
      </VStack>
    </Box>
  )
}
