import { Button } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'i18n/TranslationContext'
import { createPageAddress } from 'utils/pagination/createPageAddress'

export const PageButton = ({ number, isCurrent, pathname, search, perPage }) => {
  const { t } = useTranslation()

  return (
    <Button
      as={Link}
      colorScheme={isCurrent ? 'zettaBlue' : 'zettaPurple'}
      to={createPageAddress(pathname, search, number, perPage)}
      aria-label={isCurrent ? t('Current page {{number}}', { number }) : t('Go to page {{number}}', { number })}
      aria-current={isCurrent ? 'page' : 'false'}
      fontWeight="500"
    >
      {number}
    </Button>
  )
}
