import { IconButton } from '@chakra-ui/react'
import { Link } from 'react-router-dom'

export const NavigationButton = ({ icon, to, label }) => (
  <IconButton
    as={Link}
    colorScheme="zettaBlue"
    icon={icon}
    isRound
    to={to}
    aria-label={label}
  />
)
