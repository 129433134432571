import { useTranslation } from 'i18n/TranslationContext'
import { Show } from '@chakra-ui/react'
import { ViewCourseDetails } from './shared'
import { TableItem } from '../../shared/TableItem'
import { EnrolledButtons } from './EnrolledButtons'
import { Column1 } from './Column1'

const Column2 = ({ episodesCount }) => (
  <Show above="md">
    {episodesCount}
  </Show>
)

const CourseItem = ({ buttonsComponent, ...props }) => (
  <TableItem
    padding={['16px 24px', null, '16px']}
    borderBottom={['1px solid var(--chakra-colors-gray-100)', null, 'unset']}
    columns={[
      <Column1 key={1} {...props} />,
      <Column2 key={2} {...props} />,
      <>{buttonsComponent}</>
    ]}
  />
)

export const CourseAvailable = (props) => {
  const { t } = useTranslation()
  return (
    <CourseItem
      buttonsComponent={
        <ViewCourseDetails
          key={3}
          id={props.id}
        >
          {
            props['access?'] ?
              t('View course details') : t('Sign up for course')
          }
        </ViewCourseDetails>
      }
      {...props}
    />
  )
}
export const CourseEnrolled = (props) => {
  const { t } = useTranslation()
  return (
    <CourseItem
      buttonsComponent={
        props.waitlist ? (
          <ViewCourseDetails key={3} id={props.id}>
            {t('View course details')}
          </ViewCourseDetails>
        ) : (
          <EnrolledButtons key={3} id={props.id} />
        )
      }
      {...props}
    />
  )
}
