import { useGetCurrentUserQuery } from 'features/sessionsApi'
import { useListTalentProfilesQuery } from 'features/talentProfilesApi'
import { useDownloadFile } from 'hooks/useDownloadFile'
import { useSelector } from 'react-redux'
import { useMatch, useSearchParams } from 'react-router-dom'
import { unwrapSearchParams } from '../unwrapSearchParams'

export const useGetSearchParams = () => {
  let [searchParams] = useSearchParams()
  const isMain = !!useMatch('/talent/list')
  const isContactRequest = !!useMatch('/talent/list/view_profile/:talentProfileId/contact_request')

  if (isMain) { return searchParams }

  searchParams = unwrapSearchParams(searchParams)

  if (!isContactRequest) { return searchParams }

  return unwrapSearchParams(searchParams)
}

// Downloads a CSV file of the talent profiles for the current course
export const useTalentProfileCsvExport = () => {
  const searchParams = useGetSearchParams()
  const courseId = searchParams.get('course_id')

  const dtRegExp = /(\d{4})(\d{2})(\d{2})(\d{2})(\d{2}).*/
  const timestamp = new Date().toISOString().replace(/[-:.T]/g, '').replace(dtRegExp, 'talent-profiles-$1-$2-$3-$4$5Z')
  const filename = `${timestamp}.csv`
  const { isLoading, triggerDownload } = useDownloadFile(`/api/talent_profiles/csv?course_id=${courseId}`, filename)

  return {
    handleExportCsv: () => triggerDownload(),
    isLoading
  }
}

const useGetTalentProfileListArgs = () => {
  const searchParams = useGetSearchParams()
  const pageNumber = searchParams.get('page') || '1'
  const courseId = searchParams.get('course_id')
  const perPage = searchParams.get('per_page') || '20'
  const { lastSearchParams } = useSelector((state) => state.talentSearch)
  const {
    data: { partner_profile: { id } }
  } = useGetCurrentUserQuery()

  return {
    pageNumber,
    partnerProfileId: id,
    courseId,
    perPage: parseInt(perPage),
    searchDrawerParams: lastSearchParams
  }
}

export const useTalentProfileListData = () => {
  const args = useGetTalentProfileListArgs()
  return useListTalentProfilesQuery(args)
}
