import { Select } from '@chakra-ui/react'
import { useTranslation } from 'i18n/TranslationContext'

export const PerPageSelect = ({ currentPerPage, onPerPageChange }) => {
  const { t } = useTranslation()
  const options = [10, 20, 50, 100]

  const handleChange = (e) => {
    const newPerPage = Number(e.target.value)
    onPerPageChange?.(newPerPage)
  }

  return (
    <Select
      value={currentPerPage}
      onChange={handleChange}
      width="150px"
      aria-label={t('Results per page')}
    >
      {options.map(option => (
        <option key={option} value={option}>
          {option} {t('per page')}
        </option>
      ))}
    </Select>
  )
}
