import { Navigate, Route, Routes } from 'react-router-dom'
import { Global } from '@emotion/react'
import { Header as UnconfirmedHeader } from 'TalentProfile/Onboard/Header'
import { TalentsHeader } from './TalentsHeader'
import { useParams } from 'react-router-dom'
import { CourseFeature } from './CourseFeature'
import { CourseList } from './CourseList'
import { useGetCurrentUserQuery } from './features/sessionsApi'
import { TalentOnboard } from './TalentProfile/Onboard'
import { useGetTalentProfileQuery } from './features/talentProfilesApi'
import { AppLoadingState } from './AppLoadingState'
import { EditProfile } from './TalentProfile/Edit'
import { EmailConfirmationPage } from './EmailConfirmationPage'
import { SupportPage } from './shared/SupportPage'
import { OpportunityFeature } from './OpportunityFeature'
import { TalentUserGroups } from 'TalentProfile/Onboard/UserGroups'

const GlobalStyles = () => (
  <Global
    styles={{
      body: {
        backgroundColor: '#1A294F'
      }
    }}
  />
)

const UnconfirmedApp = () => {
  return (
    <Routes>
      <Route
        path="/*"
        element={
          <>
            <GlobalStyles />
            <UnconfirmedHeader />
            <Routes>
              <Route
                path="/email_confirmation"
                element={<EmailConfirmationPage />}
              />
              <Route
                path="/talent/onboard/self-id"
                element={<TalentUserGroups />}
              />
              <Route
                path="/talent/onboard"
                element={<TalentOnboard />}
              />
              <Route path="/sign_up/*" element={<Navigate to="/talent/onboard" replace />} />
              <Route path="*" element={<Navigate to="/talent/onboard" replace />} />
            </Routes>
          </>
        }
      />
    </Routes>
  )
}

export const TalentApp = () => {
  const {
    data: {
      'confirmed?': isConfirmed,
      talent_profile: { id },
      sign_up_track,
    }
  } = useGetCurrentUserQuery()
  const TalentsHeaderWrapper = () => {
    const { courseId } = useParams()
    return <TalentsHeader backTo={courseId && `/courses/${courseId}`} />
  }

  const { isLoading: isProfileLoading, data: profile } = useGetTalentProfileQuery({ id })

  if (isProfileLoading) {
    return <AppLoadingState />
  }

  if (!isConfirmed) {
    return <UnconfirmedApp />
  }

  const defaultCoursesRoute = sign_up_track?.course_id ? `/courses/${sign_up_track.course_id}` : '/courses'

  const userIsOnboarded = profile?.['complete?'] && profile?.['self_id_form_completed?']

  return (
    <Routes>
      <Route
        path="/*"
        element={
          <>
            <GlobalStyles />
            <TalentsHeader />
            <Routes>
              <Route
                path="/courses"
                element={<CourseList />}
              />
              <Route
                path="/opportunities/*"
                element={<OpportunityFeature />}
              />
              <Route
                path="/talent/onboard/self-id"
                element={<TalentUserGroups />}
              />
              <Route
                path="/talent/onboard"
                element={<TalentOnboard />}
              />
              <Route
                path="/talent/edit"
                element={<EditProfile />}
              />
              <Route
                path="/support"
                element={<SupportPage />}
              />
              <Route path="/sign_up/*" element={<Navigate to="/talent/onboard" replace />} />
              {!userIsOnboarded && <Route path="/sign_in/*" element={<Navigate to="/talent/onboard" replace />} />}
              <Route path="*" element={<Navigate to={defaultCoursesRoute} replace />} />
            </Routes>
          </>
        }
      />
      {/* Exception from other routes, we give the header the courseId */}
      <Route
        path="/courses/:courseId/*"
        element={
          <>
            <GlobalStyles />
            <TalentsHeaderWrapper />
            <CourseFeature />
          </>
        }
      />
    </Routes>
  )
}
