export const createPageAddress = (pathname, search, number, perPage) => {
  const searchParams = new URLSearchParams(search)
  const existingParams = {}
  searchParams.forEach((value, key) => {
    existingParams[key] = value
  })

  const newParams = {
    ...existingParams,
    page: String(number || 1),
  }

  if (perPage) {
    newParams.per_page = String(perPage)
  }

  return `${pathname}?${new URLSearchParams(newParams).toString()}`
}
