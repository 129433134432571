import { useTranslation } from 'i18n/TranslationContext'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { Box, Center, Link, Show } from '@chakra-ui/react'
import { FormProvider } from 'react-hook-form'

import { sessionsApi, useGetCurrentUserQuery } from 'features/sessionsApi'
import { useUpdateUserMutation } from 'features/usersApi'
import { useAssignUserGroupItemsMutation } from 'features/userGroupsApi'
import { useGetTalentProfileQuery } from 'features/talentProfilesApi'
import { AppLoadingState } from '../../../AppLoadingState'
import { FormControls } from '../shared/FormControls'
import { useUserGroupForm } from './useUserGroupForm'
import FormContent from './FormContent'
import { FormControlsBottom } from '../shared/FormControlsBottom'
import { Text } from '@chakra-ui/react'
import { useDispatch } from 'react-redux'


export const UserGroupForm = () => {
  const { t, Trans } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [updateUser] = useUpdateUserMutation()
  const { data: { 'confirmed?': isConfirmed, talent_profile: { id, 'complete?': isComplete }, id: userId, 'has_seen_self_id_form?': hasSeenForm } } = useGetCurrentUserQuery()
  const {
    isFetching
  } = useGetTalentProfileQuery({ id })
  const [assignUserGroupItems, { isSuccess, isLoading }] = useAssignUserGroupItemsMutation()

  // If user hasn't seen the form yet, and clicked Continue, update user.has_seen_self_id_form to true
  useEffect(() => {
    if (isLoading && !hasSeenForm) {
      updateUser({ id: userId, has_seen_self_id_form: true })
    }
  }, [isLoading, updateUser, hasSeenForm, userId])

  useEffect(() => {
    // Wait until response from Continue click comes back, then navigate to /email_confirmation
    if (!isSuccess) { return }

    // Invalidate tags so user is pulled fresh
    dispatch(sessionsApi.util.invalidateTags(['CurrentUser']))

    navigate('/email_confirmation')
  }, [isSuccess, isConfirmed, hasSeenForm, navigate, dispatch])

  const methods = useUserGroupForm()
  const { handleSubmit } = methods

  if (isFetching) {
    return <AppLoadingState />
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(assignUserGroupItems)}>
        <Show above="md">
          {!isComplete && <FormControls currentStep={2} />}
        </Show>
        <Box
          paddingTop={['0', '18px']}
          marginBottom="54px"
          bgColor="white"
        >
          <Center>
            <Center flexDirection="column" maxWidth="1020px" width="100%">
              <Box
                bg={`url(${process.env.PUBLIC_URL}/onboarding-bg.svg)`}
                bgRepeat="no-repeat"
                backgroundSize="cover"
                margin={['0 -8px', null, '0 -36px']}
                paddingTop="7px"
                paddingLeft="32px"
                paddingRight="36px"
              >
                <Box as="h1"
                  fontSize={['40px', null, '56px']}
                  fontFamily="Spline Sans Mono"
                  fontWeight="700"
                  textTransform="uppercase"
                  textAlign="center"
                >
                  {t('Voluntary Self-Identification')}
                </Box>
              </Box>
              <Box
                fontFamily="Poppins"
                padding={[
                  '44px 16px 0 16px',
                  null,
                  '42px 16px 66px 16px'
                ]}
                width="100%"
              >
                <FormContent />
                <FormControlsBottom />
                <Text mt={6} fontSize="20px" fontFamily="Poppins" textAlign="center">
                  <Trans
                    i18nKey="This personal information is covered by our <1>Privacy Policy</1>."
                    components={{ 1: <Link href="https://www.making-space.com/privacy-policy" textDecoration="underline" isExternal color="rgb(73,9,223)" /> }}
                  />
                  <br />
                  <strong>{t('We will not share it with employers.')}</strong>
                </Text>
              </Box>
            </Center>
          </Center>
        </Box>
      </form>
    </FormProvider>
  )
}
