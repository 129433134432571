import { apiSlice } from './apiSlice'

export const analyticsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    trackEvent: builder.mutation({
      query: ({ event, sourceObject, sourceObjectID, properties }) => ({
        url: '/analytics/track',
        method: 'POST',
        body: {
          event,
          source_class: sourceObject,
          source_class_id: sourceObjectID,
          properties
        },
      }),
    }),
  })
})

export const { useTrackEventMutation } = analyticsApi
