import { apiSlice } from './apiSlice'

export const userGroupsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    listUserGroups: builder.query({
      query: () => '/user_groups',
      providesTags: ['UserGroups']
    }),
    getUserUserGroupItems: builder.query({
      query: (userId) => `/users/${userId}/user_group_items`
    }),
    assignUserGroupItems: builder.mutation({
      query: ({ userId, ...restFormProps }) => ({
        url: `/users/${userId}/user_group_items`,
        method: 'POST',
        body: { user_group_items: restFormProps }
      }),
      invalidatesTags: (result, error) => error ? [] : ['UserGroups']
    }),
  })
})

export const {
  useListUserGroupsQuery,
  useGetUserUserGroupItemsQuery,
  useAssignUserGroupItemsMutation,
} = userGroupsApi
