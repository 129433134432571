import { useTranslation } from 'i18n/TranslationContext'
import { useGetCurrentUserQuery } from 'features/sessionsApi'
import { FormProvider } from 'react-hook-form'
import { Box, Center, Show } from '@chakra-ui/react'
import { FormContent } from './FormContent'
import { useUpdateTalentProfileMutation, useGetTalentProfileQuery } from 'features/talentProfilesApi'
import { AppLoadingState } from '../../AppLoadingState'
import { useProfileForm } from '../useProfileForm'
import { FormControls } from './shared/FormControls'
import { FormControlsBottom } from './shared/FormControlsBottom'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export const OnboardingForm = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [updateTalentProfile] = useUpdateTalentProfileMutation()
  const { data: { talent_profile: { id } } } = useGetCurrentUserQuery()
  const {
    isFetching,
    data: {
      'confirmed?': isConfirmed,
      'complete?': isProfileComplete,
      'self_id_form_completed?': isSelfIdFormComplete
    }
  } = useGetTalentProfileQuery({ id })
  const methods = useProfileForm()
  const { handleSubmit } = methods

  // if profile is complete, navigate to /talent/onboard/self-id
  useEffect(() => {
    if (isProfileComplete) {
      if (isSelfIdFormComplete) {
        // if user has already completed the self-id form, skip the self-id form
        if (isConfirmed) {
          navigate('/courses')
        } else {
          navigate('/email_confirmation')
        }
      } else {
        navigate('/talent/onboard/self-id')
      }
    }
  }, [isSelfIdFormComplete, isConfirmed, isProfileComplete, navigate])

  if (isFetching) {
    return <AppLoadingState />
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(updateTalentProfile)}>
        <Show above="md">
          <FormControls currentStep={1} />
        </Show>
        <Box
          paddingTop={['0', '18px']}
          marginBottom="54px"
          bgColor="white"
        >
          <Center>
            <Center flexDirection="column" maxWidth="1020px" width="100%">
              <Box
                bg={`url(${process.env.PUBLIC_URL}/onboarding-bg.svg)`}
                bgRepeat="no-repeat"
                backgroundSize="cover"
                margin={['0 -8px', null, '0 -36px']}
                paddingTop="7px"
                paddingLeft="32px"
                paddingRight="36px"
              >
                <Box as="h1"
                  fontSize={['40px', null, '56px']}
                  fontFamily="Spline Sans Mono"
                  fontWeight="700"
                  textTransform="uppercase"
                  textAlign="center"
                >
                  {t('Tell us about yourself')}
                </Box>
              </Box>
              <Box
                fontFamily="Poppins"
                padding={[
                  '44px 16px 0 16px',
                  null,
                  '72px 16px 185px 16px'
                ]}
                width="100%"
              >
                <FormContent />
                <FormControlsBottom />
              </Box>
            </Center>
          </Center>
        </Box>
      </form>
    </FormProvider>
  )
}
