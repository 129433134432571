import { Box } from '@chakra-ui/react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'i18n/TranslationContext'
import { HiChevronLeft, HiChevronRight, HiChevronDoubleLeft, HiChevronDoubleRight } from 'react-icons/hi2'
import { NavigationButton } from './NavigationButton'
import { PageButton } from './PageButton'
import { PerPageSelect } from './PerPageSelect'
import { createPageAddress } from 'utils/pagination/createPageAddress'
import { generatePageSeries } from 'utils/pagination/generatePageSeries'

export const PaginationButtons = ({ pagination, onPageChange }) => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const location = useLocation()
  const navigate = useNavigate()

  const {
    page: current,
    pages: last,
    prev,
    next
  } = pagination

  const currentPerPage = Number(searchParams.get('per_page')) || 20

  const handlePerPageChange = (newPerPage) => {
    const updatedSearchParams = new URLSearchParams(location.search)
    updatedSearchParams.set('per_page', newPerPage.toString())
    updatedSearchParams.set('page', '1')
    navigate({
      pathname: location.pathname,
      search: updatedSearchParams.toString()
    })
    onPageChange?.({ page: 1, perPage: newPerPage })
  }

  if (last < 2) return null

  const firstPageUrl = createPageAddress(location.pathname, location.search, 1)
  const prevPageUrl = createPageAddress(location.pathname, location.search, prev)
  const nextPageUrl = createPageAddress(location.pathname, location.search, next)
  const lastPageUrl = createPageAddress(location.pathname, location.search, last)
  const pageNumbers = generatePageSeries(current, last)

  return (
    <Box display="flex" gap="12px" alignItems="center" ml="140px">
      <ul style={{ display: 'flex', gap: '8px', listStyle: 'none', padding: 0, margin: 0 }}>
        {current !== 1 && (
          <li style={{ display: 'flex', gap: '8px' }}>
            <NavigationButton
              icon={<HiChevronDoubleLeft />}
              to={firstPageUrl}
              label={t('Go to first page')}
            />
            <NavigationButton
              icon={<HiChevronLeft />}
              to={prevPageUrl}
              label={t('Go to previous page {{prev}}', { prev })}
            />
          </li>
        )}
        {pageNumbers.map(pageNum => (
          <li key={pageNum}>
            <PageButton
              number={pageNum}
              isCurrent={pageNum === current}
              pathname={location.pathname}
              search={location.search}
            />
          </li>
        ))}
        {current !== last && (
          <li style={{ display: 'flex', gap: '8px' }}>
            <NavigationButton
              icon={<HiChevronRight />}
              to={nextPageUrl}
              label={t('Go to next page {{next}}', { next })}
            />
            <NavigationButton
              icon={<HiChevronDoubleRight />}
              to={lastPageUrl}
              label={t('Go to last page')}
            />
          </li>
        )}
      </ul>
      <PerPageSelect
        currentPerPage={currentPerPage}
        onPerPageChange={handlePerPageChange}
      />
    </Box>
  )
}
